<template>
  <div
    style="height: 100vh"
    class="d-flex align-center justify-space-around "
  >
    <v-col cols="3">
      <!--<v-img 
        src="../assets/tree.png" 
        width="20vw"
      >
      </v-img>-->
    </v-col>
    <v-col cols="6" class="d-flex align-center flex-column">
      <div>Something's wrong, please try later</div>
      <v-btn text @click="reloadPage" color="primary" class="mt-3">Ricarica</v-btn>
    </v-col>
    <v-col cols="3">
      <!--<v-img 
        src="../assets/tree.png" 
        width="20vw"
      >
      </v-img>-->
    </v-col>
  </div>
</template>

<script>
export default {
  name: "Crash",
  data: function() {
    return {
      reloadPath: undefined
    }
  },
  mounted: function() {
    console.log(this.$route.query)
    if(this.$route.query.resolvePath) {
      this.reloadPath = this.$route.query.resolvePath
    }
  },
  methods: {
    reloadPage() {
      this.$router.push(this.reloadPath)
    }
  }
}
</script>

<style>

</style>